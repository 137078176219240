const EDIT_TYPE = {
    EDIT: 2,
    CANCEL: -3
};
export const collapse = [
    {
        title: '战合客户销售渠道',
        ref: 'salesChannelsForm',
        formName: 'salesChannelsInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: []
    },
    {
        title: '战合客户品类结构',
        ref: 'categoryStructureForm',
        formName: 'categoryStructureInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: []
    },
    {
        title: '战合客户覆盖区域',
        ref: 'coverRegionForm',
        formName: 'coverRegionInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: []
    },
    {
        title: '战合客户代理品牌（前5名）',
        ref: 'dailiForm',
        formName: 'dailiInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: [],
        columns: [
            {
                label: '品牌商',
                prop: 'teamCustomerGroup',
                type: 'select',
                // optionsKey: 'SYS_CUST_RELATION_TYPE',
                options: {},
                filterable: true,
                width: 160,
                mustFill: true
            },
            {
                label: '年度规模（万）',
                prop: 'annualScale',
                type: 'input',
                width: 180,
                mustFill: true
            },
            {
                label: '代理层级',
                prop: 'agentLevel',
                type: 'select',
                optionsKey: 'NEOCRM_AGENT_LEVEL',
                filterable: true,
                width: 170,
                mustFill: true
            },
            {
                label: '代理开始时间',
                prop: 'proxyStartTime',
                type: 'date',
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd',
                width: 160
            },
            {
                label: '代理结束时间',
                prop: 'proxyEndTime',
                type: 'date',
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd',
                width: 160
            }
        ],
        actions: { // 表格操作列的配置
            fixedWidth: 100, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
            list: [
                {
                    label: '删除',
                    event: 'delete',
                    displayRule: (row) => {
                        if (+row.edit === EDIT_TYPE.EDIT) {
                            return true;
                        }
                    }
                }
            ]
        }
    },
    {
        title: '战合客户经营趋势',
        ref: 'kehuJingyingForm',
        formName: 'kehuJingyingInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: [
            {
                label: '规模',
                prop: 'scale',
                value: 'scale',
                type: 'input',
                rule: [
                    { required: true, message: '规模不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '利润',
                prop: 'profit',
                value: 'profit',
                type: 'input',
                rule: [
                    { required: true, message: '利润不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '品类',
                prop: 'categoryStructurePlan',
                value: 'categoryStructurePlan',
                type: 'input',
                rule: [
                    { required: true, message: '品类不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '品牌',
                prop: 'brand',
                value: 'brand',
                type: 'input',
                rule: [
                    { required: true, message: '品牌不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: 'SKU',
                prop: 'sku',
                value: 'sku',
                type: 'input',
                rule: [
                    { required: true, message: 'SKU不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '渠道',
                prop: 'channels',
                value: 'channels',
                type: 'input',
                rule: [
                    { required: true, message: '渠道不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '终端门店',
                prop: 'coverStoresPlan',
                value: 'coverStoresPlan',
                type: 'input',
                rule: [
                    { required: true, message: '终端门店不能为空', trigger: ['change', 'blur'] }
                ]
            }
        ]
    },
    {
        title: '战合客户发展/转型方向',
        ref: 'kehuZhuanxingForm',
        formName: 'kehuZhuanxingInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: [
            {
                label: '向上',
                prop: 'upPlan',
                value: 'upPlan',
                type: 'select',
                optionsKey: 'NEOCRM_UP',
                rule: [
                    { required: true, message: '向上不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '向下',
                prop: 'downPlan',
                value: 'downPlan',
                type: 'select',
                optionsKey: 'NEOCRM_DOWN',
                rule: [
                    { required: true, message: '向下不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '向前',
                prop: 'forwardPlan',
                value: 'forwardPlan',
                type: 'select',
                optionsKey: 'NEOCRM_FORWARD',
                rule: [
                    { required: true, message: '向前不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '向后',
                prop: 'backwardPlan',
                value: 'backwardPlan',
                type: 'select',
                optionsKey: 'NEOCRM_BACKWARD',
                rule: [
                    { required: true, message: '向后不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '其他',
                prop: 'otherPlan',
                value: 'otherPlan',
                type: 'input'
            }
        ]
    },
    {
        title: '战合客户合作价值点',
        ref: 'kehuHezuoForm',
        formName: 'kehuHezuoInfo',
        col: 4,
        labelWidth: '120px',
        visible: true,
        formList: [
            {
                label: '品牌新增长',
                prop: 'brandNewGrowth',
                value: 'brandNewGrowth',
                inputType: 'textarea',
                clearable: true,
                type: 'input',
                rule: [
                    { required: true, message: '品牌新增长不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '商流系统',
                prop: 'businessSystemPlan',
                value: 'businessSystemPlan',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '商流系统不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: 'B2B转型赋能',
                prop: 'b2BTransformation',
                value: 'b2BTransformation',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: 'B2B转型赋能不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '新渠道代运营',
                prop: 'newChannelOperation',
                value: 'newChannelOperation',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '新渠道代运营不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: 'C端运营',
                prop: 'cEndOperat',
                value: 'cEndOperat',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: 'C端运营不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '物流系统',
                prop: 'logisticsSystemPlan',
                value: 'logisticsSystemPlan',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '物流系统不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '物流专业化',
                prop: 'logisticsSpecialization',
                value: 'logisticsSpecialization',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '物流专业化不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '自有品牌拓展',
                prop: 'brandExpansion',
                value: 'brandExpansion',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '自有品牌拓展不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            },
            {
                label: '客情商机',
                prop: 'relationshipOppor',
                value: 'relationshipOppor',
                inputType: 'textarea',
                type: 'input',
                rule: [
                    { required: true, message: '客情商机不能为空', trigger: ['change', 'blur'] }
                ],
                span: 24,
                rows: 3
            }
        ]
    }
];
