<!-- eslint-disable max-lines -->
<template>
    <div class="strategic-cooperation-todo-div">
        <list-layout-paper v-loading="detailLoading" class="strategic-cooperation-todo">
            <template slot="header-search" class="searchBox">
                <div class="header-box">
                    <h4>战合客户信息快速维护</h4>
                    <div class="header-part">
                        <div class="header-info">商机号：{{ detailDataLocal.opportunityCode }}</div>
                        <div class="header-info">战合客户编码：{{ detailDataLocal.customerCode }}</div>
                        <div class="header-info">战合客户名称：{{ detailDataLocal.customerName }}</div>
                    </div>
                </div>
                <div class="header-btn-box">
                    <lots-button type="primary"
                        :loading="saveLoading"
                        @click="handleSave">保存
                    </lots-button>
                    <lots-button type="primary"
                        :loading="detailLoading"
                        @click="refleshPage()">重置
                    </lots-button>
                </div>
            </template>
            <template v-slot:list="{ tableHeight }">
                <div
                    class="my-content-box"
                    v-for="(item) in newConfig"
                    :key="item.formName">
                    <div v-if="item.visible">
                        <el-menu
                            :default-active="isFull"
                            class="el-menu-demo"
                            mode="horizontal">
                            <el-menu-item index="N">{{ item.title }}</el-menu-item>
                        </el-menu>
                        <!-- 战合客户销售渠道 -->
                        <edit-table-box
                            v-if="item.formName === 'salesChannelsInfo'"
                            :key="checkedKey"
                            ref="editTableBox"
                            :control="false"
                            :selection="true"
                            :columns="newColumns"
                            height="79px"
                            :tableData="totalData"
                            :allowRowClick="false"
                            @element-change="(dataObj) => handleChangeRow(dataObj, 'salesChannels')"
                            @btn-event="actionClick">
                            <template #col-header="{ item }">
                                <span v-if="item.slot">
                                    <el-checkbox
                                        v-model="salesChannelsData[item.prop].checked"
                                        @change="(data) => handleCheck(data, item.prop)"
                                        :key="item.prop">{{ item.label }}<span v-if="salesChannelsData[item.prop].checked" class="must-fill">*</span>
                                    </el-checkbox>
                                </span>
                                <span v-else>{{ item.label }}</span>
                            </template>
                        </edit-table-box>
                        <!-- 战合客户品类结构 -->
                        <el-descriptions
                            v-else-if="item.formName === 'categoryStructureInfo'"
                            title="" direction="vertical" :column="9" :border="true">
                            <el-descriptions-item label="品类">
                                <div class="my-tr-box is-up">
                                    <div class="my-tr-cell">年度规模（万）</div>
                                </div>
                                <div class="my-tr-box">
                                    <div class="my-tr-cell">核心品牌</div>
                            </div>
                            </el-descriptions-item>
                            <el-descriptions-item
                                v-for="(value, key) in categoryStructureData"
                                :key="key"
                                :label="value.optionLabel">
                                <div class="my-tr-box is-up">
                                    <el-input
                                        :key="key + 'annualScale'"
                                        clearable
                                        v-model="categoryStructureData[key]['annualScale']"
                                        @input="(data) => handleChangeCategory(key, 'annualScale', data)">
                                    </el-input>
                                </div>
                                <div class="my-tr-box">
                                    <el-input
                                        :key="key + 'coreBrand'"
                                        clearable
                                        v-model="categoryStructureData[key]['coreBrand']"
                                        @input="(data) => handleChangeCategory(key, 'coreBrand', data)">
                                    </el-input>
                                </div>
                            </el-descriptions-item>
                        </el-descriptions>
                        <!-- 覆盖区域 -->
                        <template v-else-if="item.formName === 'coverRegionInfo'">
                            <div class="tab-box">
                                <el-tag
                                    v-for="(tag, index) in addressTags"
                                    disable-transitions
                                    :key="tag.name + index"
                                    :closable="editFlag"
                                    :type="tag.type || ''"
                                    @close="handleClose(index)">
                                    {{tag.name}}
                                </el-tag>
                            </div>
                            <div class="edit-box">
                                <div class="edit-btn">
                                    <!-- <lotsButton
                                        v-if="!editFlag"
                                        type="primary"
                                        size="mini"
                                        title="编辑"
                                        @click="handleEdit">编辑
                                    </lotsButton> -->
                                    <!-- <lotsButton
                                        v-if="editFlag"
                                        type="primary"
                                        size="mini"
                                        title="取消"
                                        @click="handleCancel">取消
                                    </lotsButton> -->
                                    <!-- <lotsButton
                                        v-if="editFlag"
                                        type="primary"
                                        size="mini"
                                        title="保存"
                                        :loading="saveAddressLoading"
                                        @click="handleSaveAddress">保存
                                    </lotsButton> -->
                                </div>
                                <AddressSelect
                                    v-if="editFlag"
                                    ref="addressSelect"
                                    :clearable="true"
                                    v-model="codeArr"
                                    :basicProps="{ multiple: true, checkStrictly: true, collapseTags: true }"
                                    @getAddress="getAddress"
                                ></AddressSelect>
                            </div>
                        </template>
                        <!-- 战合客户代理品牌（前5名） -->
                        <edit-table-box
                            v-else-if="item.formName === 'dailiInfo'"
                            ref="editTableBoxDaili"
                            :control="false"
                            :selection="true"
                            :columns="dailiInfoColumns(item.columns)"
                            :height="autoTableHeight('proxyBrand')"
                            :tableData="proxyBrandList"
                            :allowRowClick="false"
                            :actions="item.actions"
                            @element-change="(dataObj) => handleChangeRow(dataObj, 'proxyBrand')">
                            <template #col-header="{ item }">
                                <span v-if="item.prop === 'teamCustomerGroup'">
                                    {{ item.label }}
                                    <el-tooltip effect="dark" placement="top">
                                        <i class="el-icon-question"></i>
                                        <template slot="content">
                                            如无法搜到品牌，请创建品牌客户系<br/>
                                        </template>
                                    </el-tooltip>
                                </span>
                                <span v-else>{{ item.label }}</span>
                            </template>
                        </edit-table-box>
                        <div v-else class="dc-form-box">
                            <dc-form
                                :loading="formLoading"
                                :ref="item.ref"
                                :refName="item.ref"
                                :row="formData"
                                :config="item.formList"
                                :col="item.col"
                                @data-changed="handleFormDataChange"
                                :labelWidth="item.labelWidth">
                            </dc-form>
                        </div>
                    </div>
                </div>
            </template>
        </list-layout-paper>
    </div>
</template>
<script>
import { ref, reactive, onActivated, computed, getCurrentInstance, onMounted } from '@vue/composition-api';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import lotsButton from '@/components/lots/lotsButton';
import editTableBox from '@/components/lots/editTableBox/Index';
import { neoTeamAccountAllocationQuery, neoTeamAccountAllocationSave } from '@mdm/api/contractManage/strategicCooperationMaintenance.js';
import { Message } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import _ from 'lodash';
import AddressSelect from '@/components/lots/otpCascader2';
import { collapse } from './config.js';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import { getCustomerGroupInfoPage } from '@mdm/api/customer/customerSeries.js';

const { MAGIC_NUMBER } = utils;
export default {
    name: 'contractRegistrationToDo',
    components: { listLayoutPaper, lotsButton, editTableBox, AddressSelect, dcForm },
    props: {},
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const columns = ref([
            {
                label: '销售渠道',
                prop: 'salesChannels',
                width: 100
            }
        ]);
        const formData = ref({});
        const formLoading = ref(false);
        const handleFormDataChange = ({ data, form, itemSetting, refName }) => {
            developPlanningLocal.value[itemSetting.prop] = data; // 编辑时同步更新当前页详情数据
        };
        const detailLoading = ref(false);
        const isFull = ref('N');
        const { user } = store.getters;
        const totalData = ref([]);
        const totalData2 = ref([]);
        const codeArr = ref([]);
        const detailDataLocal = reactive({
            accountId: '',
            customerCode: '',
            customerName: '',
            opportunityId: '',
            opportunityCode: '',
            opportunityName: ''
        });
        // 代理品牌表格数据
        const proxyBrandList = ref([]);
        const addressTags = ref([]);
        const sourceAddress = ref([]);
        const editFlag = ref(true);
        // 渠道数据绑定对象
        const salesChannelsData = reactive({});
        // 最终选择的地址对应编码对象
        const addressNameAndCode = ref({});
        // 初始化查询的地址对应编码对象
        const sourceAddressNameAndCode = ref({});
        const autoTableHeight = computed(() => {
            function getHeight(prop) {
                let length = 0;
                if (prop === 'proxyBrand') {
                    length = proxyBrandList.value.length;
                } else {
                    length = formData[prop].length;
                }
                return length ? length * (MAGIC_NUMBER.FOURTY + 1) + MAGIC_NUMBER.THIRTY + 8 + 'px' : MAGIC_NUMBER.FIFTY_ONE + 7 + MAGIC_NUMBER.FIFTY + 'px';
            }
            return getHeight;
        });
        const newConfig = computed(() => {
            return config.map(item => {
                if (['kehuJingyingInfo', 'kehuZhuanxingInfo', 'kehuHezuoInfo'].includes(item.formName)) {
                    // item.visible = !!detailDataLocal.opportunityCode;
                }
                return item;
            });
        });
        const dailiInfoColumns = computed(() => {
            function getOptions(columns) {
                const theItem = columns.find(item => item.prop === 'teamCustomerGroup');
                theItem.options = customerGroupOptions;
                return columns;
            };
            return getOptions;
        });
        const refleshPage = () => {
            fetchData();
        };
        const checkedKey = ref(false);
        const handleCheck = (data, prop) => {
            salesChannelsData[prop].coverStores = data ? salesChannelsData[prop].coverStores : null;
            totalData.value[0][prop] = data ? totalData.value[0][prop] : null;
            checkedKey.value = !checkedKey.value; // 解决表格勾选框不渲染bug
        };
        // eslint-disable-next-line complexity
        const handleChangeRow = _.debounce(({ prop, val, type, row, index }, key) => {
            if (key === 'salesChannels') { // 销售渠道表格
                const contractCountReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/; // 非负数
                const strData = val.replace(/\s*/g, '') || 0;
                if (!(contractCountReg.test(Number(strData)))) {
                    salesChannelsData[prop].coverStores = row[prop] = '';
                    return Message.warning('请输入正确的数字值');
                }
                row[prop] = val.replace(/\s*/g, '');
                // 检测全量表格所填数据自动勾选（避免因防抖而用户编辑过快，导致表头未能选中）
                Object.keys(totalData.value[0]).filter(fKey => fKey.includes('optionCode')).forEach((key, i) => {
                    salesChannelsData[key].coverStores = totalData.value[0][key];
                    salesChannelsData[key].checked = Boolean(+totalData.value[0][key]);
                });
                checkedKey.value = !checkedKey.value;
            } else if (key === 'proxyBrand') { // 代理品牌（前5名）表格
                if (prop === 'annualScale') {
                    row[prop] = val.replace(/\s*/g, '');
                    const strData = val.replace(/\s*/g, '') || 0;
                    if (!Number.isFinite(+strData)) {
                        row[prop] = '';
                        return Message.warning('请输入正确的数字值');
                    }
                } else if (['proxyStartTime', 'proxyEndTime'].includes(prop)) {
                    if (val && new Date(row['proxyEndTime']).getTime() <= new Date(row['proxyStartTime']).getTime()) {
                        row[prop] = '';
                        return Message.warning(prop === 'proxyEndTime' ? '代理结束时间不能早于代理开始时间' : '代理开始时间不能晚于代理结束时间');
                    }
                }
            }
        }, MAGIC_NUMBER.FOUR_HUNDRED);
        const actionClick = (row, event, index) => {
            if (event === 'saveRow') {
                handleSaveChannels(row);
            }
        };
        // 保存渠道校验
        const saveSalesChannelsCheck = () => {
            const stop = Object.keys(salesChannelsData).some(key => salesChannelsData[key].checked && (!salesChannelsData[key].coverStores || +salesChannelsData[key].coverStores === 0));
            if (stop) {
                Message.warning('已勾选的渠道，覆盖终端数量不能为空');
                return false;
            }
            return true;
        };
        // 保存渠道参数
        const handleSaveChannels = (row = {}) => {
            if (saveSalesChannelsCheck()) {
                const salesChannels = Object.keys(salesChannelsData).map((key) => {
                    const theItem = _.cloneDeep(salesChannelsData[key]);
                    const newItem = _.omit(theItem, ['checked']);
                    return newItem;
                }).filter(item => {
                    item.coverStores = +item.coverStores;
                    return item.coverStores || item.salesChannelsId;
                });
                const params = {
                    salesChannels,
                    userCode: user.userCode
                };
                return params;
            } else {
                return false;
            }
        };
        const handleEdit = () => {
            editFlag.value = true;
        };
        const handleClose = (index) => {
            addressTags.value.splice(index, 1);
        };
        const handleCancel = () => {
            editFlag.value = false;
            addressTags.value = _.cloneDeep(sourceAddress.value);
        };
        const saveAddressLoading = ref(false);
        // 保存覆盖区域参数
        const handleSaveAddress = () => {
            const customerTips = '客户id, 商机id不能为空';
            const customerChange = '客户id, 客户信息修改id不能为空';
            const hasCustomer = !detailDataLocal.opportunityId && !detailDataLocal.accountId;
            const hasCustomerChange = !detailDataLocal.customerChangeId && !detailDataLocal.accountId;
            if (hasCustomer && !detailDataLocal.customerChangeId) {
                Message.warning(customerTips);
                return false;
            }
            if (hasCustomerChange && !detailDataLocal.opportunityId) {
                Message.warning(customerChange);
                return false;
            }
            if (!addressTags.value.length) {
                Message.warning('战合客户覆盖区域不能为空');
                return false;
            }
            const nameArr = addressTags.value.map(item => item.name);
            const coverRegion = nameArr.join('，');
            const coverRegionList = nameArr.map(key => addressNameAndCode.value[key]);
            const params = {
                coverRegion,
                coverRegionList,
                userCode: user.userCode
            };
            return params;
        };
        // 地址选择器回调
        const getAddress = (data) => {
            if (data && data.length) {
                const newTags = data.map((item) => {
                    return {
                        name: item.join('/'),
                        type: ''
                    };
                });
                const newAddress = [...addressTags.value.filter(item => item.type), ...newTags];
                // 地址去重
                const resFn = new Map();
                const arr = newAddress.filter((item) => !resFn.has(item.name) && resFn.set(item.name, 1));
                // 地址排序
                arr.sort((a, b) => a.name.localeCompare(b.name));
                // 如果地址有子级，则过滤掉父级：如有 广东省 和 广东省/广州市两个地址，则需要删除广东省，保留 广东省/广州市
                const newArr = arr.filter(item => !arr.filter(sub => sub.name !== item.name).some(newSub => newSub.name.includes(item.name)));
                // 得到最终的地址tag
                addressTags.value = [...newArr];
                // 把新添加的地址名称-编码对应存起来
                const newNameToCode = {};
                data.forEach((item, index) => {
                    newNameToCode[item.join('/')] = codeArr.value[index];
                });
                // 获取最终选中的地址名称-地址编码对象
                const finalNameAndCode = {};
                addressTags.value.map(item => item.name).forEach(key => {
                    finalNameAndCode[key] = { ...sourceAddressNameAndCode.value, ...newNameToCode }[key];
                });
                addressNameAndCode.value = finalNameAndCode;
            } else {
                addressTags.value = addressTags.value.filter(item => item.type === 'info'); // 只保留未被手动删除的源地址
                // 获取对应的地址名称和编码对象：
                const newNameandCode = {};
                addressTags.value.map(item => item.name).forEach(key => {
                    newNameandCode[key] = sourceAddressNameAndCode.value[key];
                });
                addressNameAndCode.value = newNameandCode;
            }
        };
        // 品类结构数据对象
        const categoryStructureData = reactive({});
        const handleChangeCategory = _.debounce((key, prop, data) => {
            if (prop === 'annualScale') {
                categoryStructureData[key][prop] = data.replace(/\s*/g, '');
                const strData = data.replace(/\s*/g, '') || 0;
                if (!Number.isFinite(+strData)) {
                    categoryStructureData[key][prop] = '';
                    return Message.warning('请输入正确的数字值');
                }
                return;
            }
            categoryStructureData[key][prop] = data;
        }, MAGIC_NUMBER.FOUR_HUNDRED);
        // 品类结构保存参数
        const saveCategoryStructure = () => {
            const stop = Object.keys(categoryStructureData).some(key => categoryStructureData[key].coreBrand && !categoryStructureData[key].annualScale);
            if (stop) {
                Message.warning('战合客户品类结构中，核心品牌的年度规模不能为空');
                return false;
            }
            const params = Object.keys(categoryStructureData).map(key => {
                return categoryStructureData[key];
            });
            return params;
        };
        // 代理品牌前5保存参数
        const saveProxyBrandList = () => {
            setupContext.refs.editTableBoxDaili[0].selectedRowsData = proxyBrandList.value
                .filter(item => (item.teamCustomerGroup || item.annualScale || item.agentLevel || item.proxyStartTime || item.proxyEndTime));
            if (!setupContext.refs.editTableBoxDaili[0].selectedRowsData.length) {
                Message.warning('代理品牌数据不能为空');
                return false;
            }
            if (setupContext.refs.editTableBoxDaili[0].saveData() === false) {
                return false;
            }
            // TODO 去重校验
            return proxyBrandList.value.map(item => {
                const newItem = _.pick(item, ['proxyBrandId', 'teamCustomerGroup', 'agentLevel', 'annualScale', 'proxyStartTime', 'proxyEndTime']);
                return newItem;
            });
        };
        // 其他dc-form表单校验
        const validateForm = () => {
            const keys = ['kehuJingyingForm', 'kehuZhuanxingForm', 'kehuHezuoForm'];
            const newKeys = keys.filter(key => !!setupContext.refs[key]); // 过滤不渲染的form
            return Promise.all(newKeys.map(key => setupContext.refs[key][0].validateForm()));
        };
        const saveLoading = ref(false);
        // 保存全部数据
        const handleSave = () => {
            validateForm().then(() => {
                if (handleSaveChannels() && handleSaveAddress() && saveCategoryStructure() && saveProxyBrandList()) {
                    const { salesChannels } = handleSaveChannels();
                    const { coverRegion, coverRegionList } = handleSaveAddress();
                    const categoryStructure = saveCategoryStructure();
                    const proxyBrand = saveProxyBrandList();
                    const developPlanning = _.cloneDeep(developPlanningLocal.value);
                    const params = {
                        salesChannels,
                        coverRegion,
                        coverRegionList,
                        categoryStructure,
                        proxyBrand,
                        developPlanning,
                        ...detailDataLocal,
                        userCode: user.userCode
                    };
                    saveLoading.value = true;
                    neoTeamAccountAllocationSave(params).then(res => {
                        if (+res.code === 0) {
                            Message.success('保存成功');
                            refleshPage();
                        }
                    }).finally(() => { saveLoading.value = false; });
                }
            }).catch(() => {
                Message.warning('表单必填项不能为空');
            });
        };
        // 销售渠道表格动态列配置
        const newColumns = computed(() => {
            return columns.value;
        });
        const fileReadonly = ref(false);
        const developPlanningLocal = ref({});
        // eslint-disable-next-line max-lines-per-function
        const fetchData = () => {
            editFlag.value = true;
            const { accountId, opportunityId, customerChangeId } = ctx.$route.query;
            detailLoading.value = true;
            // eslint-disable-next-line max-lines-per-function, complexity
            neoTeamAccountAllocationQuery({ accountId, opportunityId, customerChangeId }).then(res => {
                if (+res.code === 0 && res.data) {
                    totalData.value = [];
                    const pickData = _.pick(res.data, ['accountId', 'customerCode', 'customerName', 'opportunityId', 'opportunityCode', 'opportunityName', 'customerChangeId']);
                    Object.keys(pickData).forEach(key => {
                        detailDataLocal[key] = pickData[key];
                    });
                    formData.value = res.data.developPlanning;
                    developPlanningLocal.value = _.cloneDeep(res.data.developPlanning);
                    // 组装销售渠道数据、表格列配置：
                    const dataItem = { salesChannels: '覆盖终端数量', edit: 2, saveRowLoading: false };
                    if (res.data.salesChannels && res.data.salesChannels.length) {
                        // 组装新的表格列配置项
                        const addColumns = res.data.salesChannels.map(item => {
                            const label = item.optionLabel;
                            const prop = 'optionCode' + item.optionCode;
                            dataItem[prop] = item.coverStores || null; // 组装表格数据
                            // 初始化渠道对象
                            salesChannelsData[prop] = reactive({
                                checked: false,
                                coverStores: null,
                                optionLabel: null,
                                optionCode: null,
                                salesChannelsId: null
                            });
                            // 组装渠道数据绑定对象
                            salesChannelsData[prop].checked = Boolean(item.coverStores);
                            salesChannelsData[prop].optionLabel = item.optionLabel || null;
                            salesChannelsData[prop].coverStores = item.coverStores || null;
                            salesChannelsData[prop].optionCode = item.optionCode || null;
                            salesChannelsData[prop].salesChannelsId = item.salesChannelsId || null;
                            return {
                                label,
                                prop,
                                type: 'input',
                                slot: true,
                                width: 120
                            };
                        });
                        // 初始化表格列配置
                        columns.value = [
                            {
                                label: '销售渠道',
                                prop: 'salesChannels',
                                width: 100
                            }
                        ];
                        columns.value.push(...addColumns);
                        totalData.value = [dataItem];
                        checkedKey.value = !checkedKey.value;
                    }
                    // 组装覆盖区域数据
                    if (res.data.coverRegion) {
                        const arr = res.data.coverRegion.split('，');
                        if (arr.length) {
                            // 把源地址中文名称存起来
                            sourceAddress.value = arr.map((address, index) => {
                                // 把地址名称和编码对应存起来
                                if (res.data.coverRegionList && res.data.coverRegionList.length) {
                                    sourceAddressNameAndCode.value[address] = res.data.coverRegionList[index];
                                }
                                return {
                                    name: address,
                                    type: 'info'
                                };
                            });
                        }
                        addressTags.value = _.cloneDeep(sourceAddress.value);
                        addressNameAndCode.value = _.cloneDeep(sourceAddressNameAndCode.value);
                    }
                    // 核心品类数据
                    const dataArr2 = [
                        { dateName: '年度规模（万）', edit: 2, saveRowLoading: false },
                        { dateName: '核心品牌', edit: 2, saveRowLoading: false }
                    ];
                    if (res.data.categoryStructure && res.data.categoryStructure.length) {
                        res.data.categoryStructure.forEach(item => {
                            const { annualScale, optionLabel, optionCode, categoryStructureId, coreBrand } = item;
                            const label = item.optionLabel;
                            const prop = 'optionCode' + item.optionCode;
                            dataArr2[0]['annualScale'] = item.coverStores || null; // 组装表格数据
                            dataArr2[1][prop] = item.coverStores || null; // 组装表格数据
                            // 初始化渠道对象
                            categoryStructureData[prop] = reactive({
                                annualScale: annualScale || null,
                                optionLabel: optionLabel || null,
                                optionCode: optionCode || null,
                                categoryStructureId: categoryStructureId || null,
                                coreBrand: coreBrand || null
                            });
                            return {
                                label,
                                prop,
                                type: 'input',
                                slot: true,
                                width: 120
                            };
                        });
                        totalData2.value = dataArr2;
                    }
                    // 代理品牌数据
                    const initItem = {
                        edit: 2,
                        id: undefined,
                        teamCustomerGroup: '',
                        annualScale: '',
                        agentLevel: '',
                        proxyStartTime: '',
                        proxyEndTime: ''
                    };
                    const newArr = new Array(5);
                    if (res.data.proxyBrand && res.data.proxyBrand.length) {
                        for (let i = 0; i < 5; i++) {
                            const targetItem = _.cloneDeep(res.data.proxyBrand[i]);
                            // eslint-disable-next-line max-depth
                            if (targetItem) {
                                const newItem = {
                                    ...targetItem,
                                    edit: 2,
                                    id: targetItem.proxyBrandId
                                };
                                newArr[i] = newItem;
                            } else {
                                newArr[i] = _.cloneDeep(initItem);
                            }
                        }
                    } else {
                        for (let i = 0; i < 5; i++) {
                            newArr[i] = _.cloneDeep(initItem);
                        }
                    }
                    proxyBrandList.value = newArr;
                }
            }).finally(() => { detailLoading.value = false; });
        };
        onActivated(() => {
            setupContext.refs.editTableBox[0].$refs.moduleTable.doLayout(); // 解决表格错位
        });
        const customerGroupOptions = {};
        onMounted(async() => {
            const res = await getCustomerGroupInfoPage({ pageNo: 1, pageSize: 10000 });
            if (res?.code === '0') {
                res.data.list.forEach(item => {
                    customerGroupOptions[item.customerGroupCode] = item.customerGroupName;
                });
            }
            fetchData();
        });
        return {
            config,
            newConfig,
            detailLoading,
            saveAddressLoading,
            columns,
            newColumns,
            totalData,
            addressTags,
            editFlag,
            detailDataLocal,
            refleshPage,
            isFull,
            handleChangeRow,
            saveLoading,
            handleSaveChannels,
            actionClick,
            fileReadonly,
            salesChannelsData,
            handleCheck,
            checkedKey,
            handleClose,
            handleEdit,
            codeArr,
            getAddress,
            handleCancel,
            handleSaveAddress,
            categoryStructureData,
            handleChangeCategory,
            formData,
            formLoading,
            handleFormDataChange,
            proxyBrandList,
            autoTableHeight,
            handleSave,
            dailiInfoColumns
        };
    }
};
</script>
<style lang="less">
.strategic-cooperation-todo-div {
    width: 100%;
    height: 100%;
    .strategic-cooperation-todo {
        height: 100%;
        .list-layout {
            .list-header {
                margin-bottom: 0;
            }
            .list-main {
                overflow: auto;
                .my-content-box:nth-child(-n+4) { // 前4个my-content-box
                    padding-bottom: 18px;
                }
                .edit-table-box {
                    width: 980px;
                }
                .dc-form-box {
                    margin-top: 10px;
                }
                .my-content-box {
                    padding-right: 10px;
                }
            }
        }
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
        }
        .header-part {
            display: flex;
            // justify-content: space-between;
            .header-info {
                width: 340px;
                font-size: 14px;
                color: #606266;
                height: 26px;
                line-height: 26px;
            }
        }
        .el-menu.el-menu--horizontal {
            width: 150px;
            .el-menu-item {
                height: 35px;
                line-height: 35px;
                font-weight: 600;
                &.is-active {
                    color: #4285F5
                }
            }
        }
        .must-fill {
            display: inline-block;
            color: red;
            margin-left: 2px;
        }
        .tab-box {
            width: 980px;
            min-height: 30px;
            background-color: #e5e6e7;
            padding: 5px 0 0 5px;
            box-sizing: border-box;
            .el-tag {
                margin: 0 5px 5px 0;
            }
        }
        .edit-box {
            margin-top: 5px;
            display: flex;
            width: 980px;
            .m-otp-cascader-content {
                // margin-left: 10px;
                flex: 1;
            }
        }
        .el-descriptions--mini.is-bordered .el-descriptions-item__cell.el-descriptions-item__content {
            padding: 0;
        }
        .el-descriptions-row {
            & td:first-child {
                width: 120px;
            }
        }
        .my-tr-box {
            padding: 6px 10px;
            width: 100%;
            box-sizing: border-box;
            .my-tr-cell {
                line-height: 28px;
            }
            .el-input {
                width: 100%;
                .el-input__inner {
                    width: 100%;
                    padding-right: 5px;
                }
            }
            &.is-up {
                width: 100%;
                border-bottom: 1px solid #ebeef5;
            }
        }
        .el-select {
            width: 100%;
        }
    }
}
.el-cascader-panel .el-cascader-menu:nth-child(4) {
    display: none;
}
.el-cascader-panel .el-cascader-menu:nth-child(3) {
    .el-icon-arrow-right {
        display: none;
    }
}
</style>
