import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 战合客户信息维护页面-查询
export const neoTeamAccountAllocationQuery = (data) => {
    return request({
        url: apiCrmHost + '/neo/teamAccount/allocation/query',
        method: 'post',
        data
    });
};
// 销售渠道-保存
export const neoTeamAccountSalesChannelsSave = (data) => {
    return request({
        url: apiCrmHost + '/neo/teamAccount/salesChannels/save',
        method: 'post',
        data
    });
};
// 覆盖区域-保存
export const neoTeamAccountCoverRegionSave = (data) => {
    return request({
        url: apiCrmHost + '/neo/teamAccount/coverRegion/save',
        method: 'post',
        data
    });
};
// 保存-全部信息
export const neoTeamAccountAllocationSave = (data) => {
    return request({
        url: apiCrmHost + '/neo/teamAccount/allocation/save',
        method: 'post',
        data
    });
};
